import { Injectable } from '@angular/core'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { Select, Store } from '@ngxs/store'
import { BehaviorSubject, Observable } from 'rxjs'
import { AscDesc, Channel, ChannelAPIResponse } from 'stream-chat'
import { ChannelService, ChatClientService, DefaultStreamChatGenerics, StreamI18nService } from 'stream-chat-angular'
import { environment } from '../../../environments/environment'
import { SetUnreadChatMessagesCount } from '../../module/dashboard/states/dashboard.actions'
import { DashboardState } from '../../module/dashboard/states/dashboard.state'
import { ChatEvent, ChatType } from '../../module/dashboard/tabs/communication-tab/chat/models/chat.models'
import { translations } from '../../module/dashboard/tabs/communication-tab/chat/models/translations.model'
import { GlobalState } from '../../shared/states/global.state'
import Languages from '../enums/organization/languages'
import { CurrentUserResponse } from '../interfaces/user/current-user-response'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  @Select(GlobalState.currentLanguage) currentLanguage: Observable<Languages>
  @SelectSnapshot(DashboardState.user) currentUser: CurrentUserResponse

  channels: Channel<DefaultStreamChatGenerics>[] = []
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(
    private store: Store,
    private chatClientService: ChatClientService,
    public channelService: ChannelService,
    private authService: AuthService,
    private streamI18nService: StreamI18nService
  ) {}

  initChat(userId: string): void {
    const apiKey = environment.streamChatKey
    this.authService.getStreamToken().subscribe((token) => {
      const userToken = token
      this.chatClientService.init(apiKey, userId, userToken, { timeout: 10000 })
      this.initChatChannels(userId).then((channels) => {
        this.isLoading.next(false)
        if (channels.length) {
          this.channels = channels
        }
        this.connectionListener(userId)
        this.unreadTotalCountListener()
      })
    })
  }

  async initChatChannels(userId: string): Promise<Channel<DefaultStreamChatGenerics>[]> {
    this.isLoading.next(true)
    const filter = { type: ChatType.Messaging, members: { $in: [userId] } }
    const sort = { last_message_at: -1 as AscDesc }

    this.currentLanguage.subscribe((language) =>
      this.streamI18nService.setTranslation(language, translations[language])
    )
    return this.channelService.init(filter, sort)
  }

  private unreadTotalCountListener(): void {
    const client = this.chatClientService.chatClient
    client.on((event) => {
      if (
        event.total_unread_count !== undefined &&
        (event.type === ChatEvent.MessageNew || event.type === ChatEvent.NotificationMarkRead)
      ) {
        this.store.dispatch(new SetUnreadChatMessagesCount(event.total_unread_count))
      }
    })
  }

  private connectionListener(userId): void {
    this.chatClientService.chatClient?.on((event) => {
      if (event.type === ChatEvent.ConnectionChanged && !event.online) {
        // Temporary console.log to debug client connection on prod
        console.log(event.type)
        this.authService.getStreamToken().subscribe((token) => {
          const userToken = token
          this.chatClientService.chatClient.connectUser({ id: userId }, userToken)
        })
      }
    })
  }

  async createChannel(userUUID: string): Promise<ChannelAPIResponse<DefaultStreamChatGenerics>> {
    const channel = this.chatClientService.chatClient.channel(ChatType.Messaging, {
      watch: true,
      state: true,
      presence: true,
      shouldSetActiveChannel: true,
      members: [this.currentUser.uuid, userUUID]
    })
    return await channel.create()
  }

  // setActiveChannel(channelId): void {
  //   this.channelService.init({ type: ChatType.Messaging, id: { $eq: channelId } })
  //   this.channelService.channels$.subscribe((channels) => {
  //     const activeChannel = channels?.find((channel) => channel.id === channelId)
  //     if (activeChannel) {
  //       this.channelService.setAsActiveChannel(activeChannel)
  //     }
  //   })
  // }

  async searchByChatName(chatName: string): Promise<void> {
    const filter = {
      type: ChatType.Messaging,
      members: { $in: [this.currentUser.uuid] },
      name: { $autocomplete: chatName }
    }
    const sort = { last_message_at: -1 as AscDesc }

    const channels = await this.chatClientService.chatClient.queryChannels(filter, sort, { limit: 30 })
    if (channels.length) {
      const channelsIds = channels.map((channel: Channel) => channel.id)

      this.channelService.init({
        type: ChatType.Messaging,
        id: { $in: channelsIds as string[] }
      })
    }
  }
}
